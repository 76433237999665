<p-blockUI [blocked]="progress"></p-blockUI>
<div class="col-10 col-offset-1">
  <div class="text-xl font-bold">
    {{ "signup.newCustomerTitle" | translate }}
  </div>
  <div class="mt-2">
    {{ "signup.newCustomerDetails" | translate
    }}<a [routerLink]="'/login'" class="text-red-700">{{
      "signup.signInHere" | translate
      }}</a>
  </div>

  <div class="bg-red-100 my-2 p-2 pr-5 border-round font-bold text-gray-800">
    {{ "signup.newCustomerDetails2" | translate }}
  </div>
  <div class="text-red-700 mt-2">
    {{ "signup.markedFields" | translate }}<sup>*</sup>
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="mt-2 grid">
      <div class="col-12 md:col-6 field">
        <label for="email">{{ "base.email" | translate
          }}<span class="text-red-700">*</span></label>
        <input pInputText type="text" id="email" class="w-full" placeholder="{{ 'base.email' | translate }}"
          formControlName="email" />
        <div class="bg-red-100 p-1" *ngIf="errors && errors['email']">
          {{ errors["email"] }}
        </div>
      </div>
      <!-- Email -->
      <div class="col-12 md:col-6">
        <div class="grid">
          <div class="col-5 sm:col-3 field">
            <label for="phone">{{ "base.phone" | translate
              }}<span class="text-red-700">*</span></label>
            <p-dropdown inputId="phone" [options]="systemService.countries" [(ngModel)]="selectedCountry"
              [ngModelOptions]="{ standalone: true }" optionLabel="name" [filter]="true" filterBy="name,phonecode"
              [autoDisplayFirst]="true" styleClass="w-full">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="selectedCountry" class="fi2">
                  <span [class]="'mr-2 ' + selectedCountry.iso2?.toUpperCase()"></span>
                  {{ selectedCountry.phonecode }}
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="fi2">
                  <span [class]="'mr-2 ' + country.iso2?.toUpperCase()"></span>{{ country.phonecode }}
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field col-7 sm:col-9">
            <label for="phone">&nbsp;</label>
            <p-inputMask [tabindex]="'0'" (onFocus)="onPhoneMaskFocus($event)" inputId="phone" formControlName="phone"
              mask="999999?999999" placeholder="999999" [unmask]="true" [autoClear]="false"></p-inputMask>
            <div class="bg-red-100 p-1" *ngIf="phone?.errors?.['minlength']">
              {{ "signup.phoneMinLength" | translate }}
            </div>
            <div class="bg-red-100 p-1" *ngIf="errors && errors['phone']">
              {{ errors["phone"] }}
            </div>
          </div>
        </div>
      </div>
      <!-- Phone -->
      <div class="col-12 md:col-6 field">
        <label for="password">{{ "base.password" | translate
          }}<span class="text-red-700">*</span></label>
        <p-password id="password" inputStyleClass="w-full"
          styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right" formControlName="password"
          [toggleMask]="true"></p-password>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['password']">
          {{ errors["password"] }}
        </div>
      </div>
      <!-- Password -->
      <div class="col-12 md:col-6 field">
        <label for="confirm_password">{{ "base.password2" | translate
          }}<span class="text-red-700">*</span></label>
        <p-password id="confirm_password" inputStyleClass="w-full"
          styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right" class="w-full"
          formControlName="confirm_password" [toggleMask]="true"></p-password>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['confirm_password']">
          {{ errors["confirm_password"] }}
        </div>
      </div>
      <!-- Password confirm -->
      <div class="col-12 md:col-6 field">
        <label for="last_name">{{ "base.lastname" | translate
          }}<span class="text-red-700">*</span></label>
        <input pInputText type="text" id="last_name" class="w-full" placeholder="{{ 'base.lastname' | translate }}"
          formControlName="last_name" />
        <div class="bg-red-100 p-1" *ngIf="errors && errors['last_name']">
          {{ errors["last_name"] }}
        </div>
      </div>
      <!-- Lastname -->
      <div class="col-12 md:col-6 field">
        <label for="first_name">{{ "base.firstname" | translate
          }}<span class="text-red-700">*</span></label>
        <input pInputText type="text" id="first_name" class="w-full" placeholder="{{ 'base.firstname' | translate }}"
          formControlName="first_name" />
        <div class="bg-red-100 p-1" *ngIf="errors && errors['first_name']">
          {{ errors["first_name"] }}
        </div>
      </div>
      <!-- Firstname -->
      <div class="col-12 mt-2">
        {{ "base.company" | translate }} / {{ "base.person" | translate }}
      </div>
      <div class="col-12 flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <p-radioButton name="is_company" id="isCompany1" value="1" formControlName="is_company"
            inputId="isCompany1"></p-radioButton>
          <label for="isCompany1" class="ml-2">{{
            "base.company" | translate
            }}</label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton name="is_company" id="isCompany0" value="0" formControlName="is_company"
            inputId="isCompany0"></p-radioButton>
          <label for="isCompany0" class="ml-2">{{
            "base.person" | translate
            }}</label>
        </div>
      </div>
      <!-- isCompany ? -->
      <div class="col-12 md:col-6" *ngIf="registerForm.value.is_company == '1'">
        <label for="company_name" class="col-12 md:col-2">{{
          "base.company_name" | translate
          }}</label>
        <div class="col-12">
          <input type="text" id="company_name" pInputText class="w-full" formControlName="company_name" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['company_name']">
            {{ errors["company_name"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6" *ngIf="registerForm.value.is_company == '1'">
        <label for="vat_number" class="col-12 md:col-2">{{
          "base.vat_number" | translate
          }}</label>
        <div class="col-12">
          <input type="text" id="vat_number" pInputText class="w-full" formControlName="vat_number" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['vat_number']">
            {{ errors["vat_number"] }}
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        {{ "base.soloOrBand" | translate }}
      </div>
      <div class="col-12 flex flex-wrap gap-3">
        <p-radioButton name="solo_or_band" id="soloOrBand1" value="solo" formControlName="solo_or_band"
          inputId="soloOrBand1"></p-radioButton>
        <label for="soloOrBand1" class="ml-2">{{
          "base.solo" | translate
          }}</label>

        <div class="flex align-items-center">
          <p-radioButton name="solo_or_band" id="soloOrBand2" value="band" formControlName="solo_or_band"
            inputId="soloOrBand2"></p-radioButton>
          <label for="soloOrBand2" class="ml-2">{{
            "base.band" | translate
            }}</label>
        </div>
      </div>
      <div class="col-12 mt-2">
        {{ "base.contracted" | translate }}
      </div>
      <div class="col-12 bg-red-100 my-2 p-2 pr-5 border-round font-bold text-gray-800">
        {{ "signup.newCustomerDetails3" | translate }}
      </div>
      <div class="col-12 flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <p-radioButton name="have_contract" id="haveContract1" value="1" formControlName="have_contract"
            inputId="haveContract1"></p-radioButton>
          <label for="haveContract1" class="ml-2">{{
            "base.yes" | translate
            }}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton name="have_contract" id="haveContract0" value="0" formControlName="have_contract"
            inputId="haveContract0"></p-radioButton>
          <label for="haveContract0" class="ml-2">{{
            "base.no" | translate
            }}</label>
        </div>
      </div>

      <div *ngIf="registerForm.value.have_contract == '1'" class="col-12 md:col-6 field">
        <label for="contract_number">{{ "base.contractNumber" | translate
          }}<span class="text-red-700">*</span></label>
        <input pInputText type="text" id="contract_number" class="w-full"
          [placeholder]="'base.contractNumber' | translate" formControlName="contract_number" />
        <div class="bg-red-100 p-1" *ngIf="errors && errors['contract_number']">
          {{ errors["contract_number"] }}
        </div>
      </div>
      <!-- Szerződésszám -->
      <div class="col-12 mt-2">
        {{ "billing.address" | translate }}
      </div>
      <!-- Billing address -->
      <div class="col-12 md:col-6">
        <label for="addresses[billing][name]" class="col-12 md:col-2">{{ "billing.name" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][name]" class="w-full" pInputText
            formControlName="addresses[billing][name]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.name']">
            {{ errors["addresses.billing.name"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][country]" class="col-12 md:col-2">{{
          "address.country" | translate
          }}</label>
        <div class="col-12">
          <p-dropdown id="addresses[billing][country]" class="w-full" styleClass="w-full p-component p-inputwrapper"
            [options]="systemService.countries" optionLabel="name" optionValue="iso2" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="{{ 'address.SelectCountry' | translate }}"
            formControlName="addresses[billing][country]">
          </p-dropdown>
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.country']">
            {{ errors["addresses.billing.country"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][zip_code]" class="col-12 md:col-2">{{ "billing.zip" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][zip_code]" class="w-full" pInputText
            formControlName="addresses[billing][zip_code]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.zip_code']">
            {{ errors["addresses.billing.zip_code"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][city]" class="col-12 md:col-2">{{ "billing.city" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][city]" class="w-full" pInputText
            formControlName="addresses[billing][city]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.city']">
            {{ errors["addresses.billing.city"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][street]" class="col-12 md:col-2">{{ "billing.street" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][street]" class="w-full" pInputText
            formControlName="addresses[billing][street]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.street']">
            {{ errors["addresses.billing.street"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][street_type]" class="col-12 md:col-2">{{ "billing.streetType" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][street_type]" class="w-full" pInputText
            formControlName="addresses[billing][street_type]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.street_type']">
            {{ errors["addresses.billing.street_type"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][number]" class="col-12 md:col-2">{{ "billing.houseNumber" | translate
          }}<span class="text-red-700">*</span></label>
        <div class="col-12">
          <input type="text" id="addresses[billing][number]" class="w-full" pInputText
            formControlName="addresses[billing][number]" />
          <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.billing.number']">
            {{ errors["addresses.billing.number"] }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label for="addresses[billing][line_one]" class="col-12 md:col-2">{{
          "billing.flat" | translate
          }}</label>
        <div class="col-12">
          <input type="text" id="addresses[billing][line_one]" class="w-full" pInputText
            formControlName="addresses[billing][line_one]" />
        </div>
      </div>
      <div class="col-12 mt-2">
        {{ "postal.address" | translate }}
      </div>
      <div class="field-checkbox col-12 mt-2">
        <p-checkbox [binary]="true" id="isPostalSame" [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="isPostalSame"></p-checkbox>
        <label for="isPostalSame">{{ "postal.same" | translate }}</label>
      </div>
      <!-- Postal same? -->
      <!-- Postal Address -->
      <div class="col-12 grid" *ngIf="!isPostalSame">
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][name]" class="col-12 md:col-2">{{ "billing.name" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][name]" class="w-full" pInputText
              formControlName="addresses[mailing][name]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.name']">
              {{ errors["addresses.mailing.name"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][country]" class="col-12 md:col-2">{{
            "address.country" | translate
            }}</label>
          <div class="col-12">
            <p-dropdown id="addresses[mailing][country]" class="w-full" styleClass="w-full p-component p-inputwrapper"
              [options]="systemService.countries" optionLabel="name" optionValue="iso2" [filter]="true" filterBy="name"
              [showClear]="true" placeholder="{{ 'address.SelectCountry' | translate }}"
              formControlName="addresses[mailing][country]">
            </p-dropdown>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][zip_code]" class="col-12 md:col-2">{{ "billing.zip" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][zip_code]" class="w-full" pInputText
              formControlName="addresses[mailing][zip_code]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.zip_code']">
              {{ errors["addresses.mailing.zip_code"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][city]" class="col-12 md:col-2">{{ "billing.city" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][city]" class="w-full" pInputText
              formControlName="addresses[mailing][city]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.city']">
              {{ errors["addresses.mailing.city"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][street]" class="col-12 md:col-2">{{ "billing.street" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][street]" class="w-full" pInputText
              formControlName="addresses[mailing][street]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.street']">
              {{ errors["addresses.mailing.street"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][street_type]" class="col-12 md:col-2">{{ "billing.streetType" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][street_type]" class="w-full" pInputText
              formControlName="addresses[mailing][street_type]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.street_type']">
              {{ errors["addresses.mailing.street_type"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][number]" class="col-12 md:col-2">{{ "billing.houseNumber" | translate
            }}<span class="text-red-700">*</span></label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][number]" class="w-full" pInputText
              formControlName="addresses[mailing][number]" />
            <div class="bg-red-100 p-1" *ngIf="errors && errors['addresses.mailing.number']">
              {{ errors["addresses.mailing.number"] }}
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <label for="addresses[mailing][line_one]" class="col-12 md:col-2">{{
            "billing.flat" | translate
            }}</label>
          <div class="col-12">
            <input type="text" id="addresses[mailing][line_one]" class="w-full" pInputText
              formControlName="addresses[mailing][line_one]" />
          </div>
        </div>
      </div>
      <div class="col-12 bg-red-100 my-2 p-2 pr-5 border-round font-bold text-gray-800">
        {{ "signup.newCustomerDetails4" | translate }}
      </div>
      <div class="col-12 sm:col-4">
        <p-fileUpload name="photo_id_front" [customUpload]="true" [auto]="false" (onSelect)="onUploadIdFront($event)"
          [multiple]="false" accept="image/*" [chooseLabel]="'signup.cardFile' | translate" [showUploadButton]="false"
          [showCancelButton]="false">
        </p-fileUpload>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['photo_id_front']">
          {{ errors["photo_id_front"] }}
        </div>
      </div>
      <div class="col-12 sm:col-4">
        <p-fileUpload name="photo_id_back" [customUpload]="true" [auto]="false" (onSelect)="onUploadIdBack($event)"
          [multiple]="false" accept="image/*" [chooseLabel]="'signup.cardFile' | translate" [showUploadButton]="false"
          [showCancelButton]="false">
        </p-fileUpload>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['photo_id_back']">
          {{ errors["photo_id_back"] }}
        </div>
      </div>
      <div class="col-12 sm:col-4">
        <p-fileUpload name="photo_address_front" [customUpload]="true" [auto]="false"
          (onSelect)="onUploadAddressFront($event)" [multiple]="false" accept="image/*"
          [chooseLabel]="'signup.cardFile2' | translate" [showUploadButton]="false" [showCancelButton]="false">
        </p-fileUpload>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['photo_address_front']">
          {{ errors["photo_address_front"] }}
        </div>
      </div>
      <div class="col-12">
        <p-divider></p-divider>
      </div>
      <div class="col-12 sm:col-8 sm:col-offset-2">
        <p-checkbox [binary]="true" formControlName="room_policy" [label]="'signup.acceptRoomPolicy' | translate">
        </p-checkbox>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['room_policy']">
          {{ errors["room_policy"] }}
        </div>
      </div>
      <!-- Room policy -->
      <div class="col-12 sm:col-8 sm:col-offset-2">
        <p-checkbox [binary]="true" formControlName="privacy_policy" styleClass="mr-2">
        </p-checkbox>
        <label>{{ "signup.acceptTermsVerb" | translate }}
          <a class="terms" [href]="systemService.settings.legal.terms" target="_blank">{{ "signup.termsOfService" |
            translate }}</a></label>
        <div class="bg-red-100 p-1" *ngIf="errors && errors['privacy_policy']">
          {{ errors["privacy_policy"] }}
        </div>
      </div>
      <!-- Privacy policy -->
      <div class="col-12 sm:col-8 sm:col-offset-2">
        <!-- Newsletter -->
        <p-checkbox [binary]="true" formControlName="newsletter" [label]="'signup.subscribeNewsLetter' | translate">
        </p-checkbox>
      </div>
      <!-- Newsletter -->
      <div *ngIf="progress" class="col-12 mt-2">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <div class="col-12 mt-2">
        <p-button type="submit" [label]="'base.signup' | translate" styleClass="mb-3"></p-button>
      </div>
      <!--  Signup button  -->
      <!-- <div class="col-12 bg-red-200 p-2" *ngFor="let error of errors | keyvalue">{{ 'base.error' | translate }}
        : {{ error.value }}</div>-->
    </div>
  </form>
</div>
